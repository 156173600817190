.faq-answer {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.5s ease, max-height 0.5s ease;
    
  }
  
  .faq-answer-enter {
    opacity: 1;
    max-height: 140px; /* Adjust based on content */
  }